import { Component, OnInit } from "@angular/core";
import { LoginService } from "src/app/services/admin-plus/login.service";
import { CustomerService } from "src/app/services/admin-plus/customer.service";
import { NotificationService } from "src/app/services/utilities/notification.service";
import { ServiceAccountService } from "src/app/services/admin-plus/service-account.service";
import { Router } from "@angular/router";
import { NavService } from "src/app/services/admin-plus/nav.service";
import { ModulesService } from "src/app/services/modules.service";
import { UsersService } from "src/app/services/google/users.service";
import { LeftNavService } from "src/app/services/admin-plus/leftnav.service";
import { AuthService } from "src/app/services/admin-plus/auth.service";
import { User, Permission, Scope } from "./user";
import { TosPromptComponent } from "src/app/web/login/tos-prompt/tos-prompt.component";
import { EuaPromptComponent } from "src/app/web/login/eua-prompt/eua-prompt.component";
import { MatDialog } from "@angular/material/dialog";
import { FreeTrialService, FreeTrial } from "src/app/services/admin-plus/free-trial.service";
import { AuthObject } from "src/app/models/admin-plus/base";
import { RenewTokenDialogComponent } from "src/app/layout/dialogs/renew-token-dialog/renew-token-dialog.component";
import { TimesService } from "src/app/services/utilities/times.service";
import { UserPromptComponent } from "./user-prompt/user-prompt.component";

@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
	validatingToken = false;
	tokenValidated = false;
	gettingModules = false;
	modulesRetrieved = false;
	checkingTosAccepted = false;
	tosAccepted = false;
	gettingAccessToken = false;
	accessTokenRetrieved = false;
	applicationCustomized = false;
	customizingApplication = false;

	constructor(
		public loginService: LoginService,
		private notificationService: NotificationService,
		private customerService: CustomerService,
		private serviceAccountService: ServiceAccountService,
		private router: Router,
		private modulesService: ModulesService,
		private freeTrialService: FreeTrialService,
		private navService: NavService,
		public authService: AuthService,
		private leftNavService: LeftNavService,
		private usersService: UsersService,
		public dialog: MatDialog,
		private timesService: TimesService
	) {}

	ngOnInit(): void {
		this.leftNavService.hideSignInWithGoogle = true;
		this.navService.loading.next(true);
		this.validatingToken = true;
		const authObject: AuthObject = this.loginService.parseParams(window.location.hash.substring(1));
		const state = authObject["state"];
		const idToken = authObject["id_token"];
		const accessToken = authObject["access_token"];

		if (state === "free-trial") {
			this.freeTrialSetup(authObject);
		} else if (idToken) {
			this.tokenValidated = true;
			this.login(authObject);
		} else if (accessToken) {
			this.tokenValidated = true;
			this.gettingModules = true;
			this.modulesRetrieved = true;
			this.checkingTosAccepted = true;
			this.tosAccepted = true;
			this.gettingAccessToken = true;
			this.accessTokenRetrieved = true;

			//Get user from local storage...
			const user: User = JSON.parse(localStorage.getItem("user") || "{}");
			if (user.id) {
				//user.authToken = accessToken;
				this.customizeApp(authObject);
			} else {
				this.undoLoginAction("Invalid user object");
			}
		} else {
			this.undoLoginAction("Invalid ID Token");
		}
		this.navService.loading.next(false);
	}

	private async customizeApp(authObject) {
		if (authObject.code) this.createUserAuthCode(authObject.code);
		this.customizingApplication = true;
		const modules: string = localStorage.getItem("modules");
		let userModules: string[] = [];

		try {
			userModules = JSON.parse(modules);
		} catch {
			console.error("Unable to parse user modules");
		}

		this.updatePermissions(authObject);
		this.updateUser(this.loginService.user);

		this.applicationCustomized = true;

		this.leftNavService.modules = userModules;
		localStorage.setItem("modules", JSON.stringify(userModules));

		window["Beacon"]("identify", {
			name: this.loginService.user["firstName"] + " " + this.loginService.user["lastName"],
			email: this.loginService.user.email,
		});

		if (this.loginService.user["isAdmin"]) {
			const customer = await this.customerService.getCustomer();
			const serviceAccountId = customer.serviceAccount?.id;
			if (serviceAccountId) this.serviceAccountService.checkScopes(serviceAccountId);

			if ((customer.subscription.id === 1 && !customer.name?.length) || customer?.subscription?.expired) {
				localStorage.setItem("target", "account");
			}
		}

		window["Beacon"]("prefill", {
			fields: [
				{
					id: 42215, // dropdown field ID
					value: 220762, // option ID
				},
				{
					id: 42383, //
					value: this.loginService.user.email.split("@")[1], // domain
				},
			],
		});

		//window.location.href = "/" + (localStorage.getItem("target") || "dashboard");
		//console.log("Redirecting");
		this.loginRedirect();
	}

	async loginRedirect() {
		const target: string = localStorage.getItem("target");
		if (target) {
			localStorage.removeItem("target");
			if (target != "/") {
				this.router.navigateByUrl(target);
			} else {
				this.router.navigateByUrl("dashboard");
			}
		} else {
			this.router.navigateByUrl("dashboard");
		}

		this.navService.loading.next(false);
	}

	private async updateUser(user: User) {
		localStorage.setItem("user", JSON.stringify(user));
		this.loginService.user = user;
	}

	private async freeTrialSetup(authObject) {
		const idToken = authObject["id_token"];
		const accessToken = authObject["access_token"];
		this.authService.getUserInfo(idToken).subscribe(async (account) => {
			//needs to be better
			if (!account) return;

			const adminUserData = await this.usersService.getAdminUser(account["email"], accessToken);

			if (!adminUserData?.isAdmin) {
				this.notificationService.notify("Only a Google Workspace Super Admin can register");
				this.navService.loading.next(false);
			}

			const googleCustomer = await this.freeTrialService.getGoogleCustomer(accessToken);

			if (!googleCustomer) {
				this.notificationService.notify("Unable to retrieve google customer data");
				this.navService.loading.next(false);
				return;
			}

			const freeTrial: FreeTrial = {
				organization: googleCustomer["postalAddress"]["organizationName"],
				firstName: adminUserData["name"].givenName,
				lastName: adminUserData["name"].familyName,
				email: adminUserData["primaryEmail"],
				domain: account["hd"],
				customerId: adminUserData["customerId"],
				numUsers: 1,
			};

			const threeDaysAgo = new Date(Date.now() - 24 * 3 * 3600 * 1000);
			const isoDate = this.timesService.DateToISO8601(threeDaysAgo);
			const usageReport = await this.freeTrialService.getNumberOfUsers(isoDate, accessToken);

			if (!usageReport?.usageReports?.length) {
				this.userPrompt(authObject, freeTrial);
				return;
			}

			freeTrial.numUsers = parseInt(usageReport.usageReports[0].parameters[0].intValue) || 1;

			//Backend verification method
			const response = await this.freeTrialService.createFreeTrial(freeTrial);
			if (response) {
				this.login(authObject);
			}
		});
	}

	private async login(authObject: AuthObject) {
		this.gettingModules = true;
		const userAccessToken = await this.createUserAuthCode(authObject.code);

		if (userAccessToken?.error) {
			this.undoLoginAction(userAccessToken.error, true);
			return;
		} else if (!userAccessToken?.accessToken) {
			this.undoLoginAction("Invalid Access Token");
			return;
		}

		const permission: Permission = {
			name: "google",
			expires: new Date().getTime() + userAccessToken.expires,
			accessToken: userAccessToken.accessToken,
			idToken: authObject.id_token,
			scopes: authObject.scope.split(" ").map((scope) => {
				return { name: scope, accepted: true, required: true };
			}),
		};

		//We don't have the rest of the user info yet, but we have the
		const user: User = {
			permissions: [permission],
		};

		localStorage.setItem("user", JSON.stringify(user));

		const userModules = await this.modulesService.getModules();

		if (!userModules) {
			//Response SHOULD come back from the the API
			this.undoLoginAction(null);
			return;
		}

		if (!userModules.modules || !userModules.modules.length) {
			this.undoLoginAction(
				"Failed to login, no modules returned - your account may not be setup yet.  Please contact google.support@trafera.com for assistance"
			);
			return;
		}

		this.modulesRetrieved = true;

		//Check TOS
		this.checkingTosAccepted = true;
		if (!userModules.tos || userModules.tos == "required") {
			this.undoLoginAction("Account setup has not been completed");
			return;
		}

		if (userModules.tos == "available") {
			this.legalPrompt(userModules, authObject.id_token, "tos");
			return;
		}

		if (!userModules.user.euaSigned || userModules.user.euaSigned == "") {
			this.legalPrompt(userModules, authObject.id_token, "eua");
			return;
		}

		this.prepareAccessTokenRequest(userModules, authObject.id_token);
	}

	private async createUserAuthCode(code: string) {
		const userAccessToken = await this.authService.createUserAuthCode(code);
		return userAccessToken;
	}

	private async prepareAccessTokenRequest(userModules, idToken) {
		history.replaceState(null, "", window.location.href.replace(window.location.hash, ""));
		this.tosAccepted = true;
		this.gettingAccessToken = true;
		this.loginService.user = { ...this.loginService.user, ...userModules["user"] };
		this.loginService.user.idToken = idToken;
		this.loginService.user.customerID = userModules["customer"]["customerId"];
		localStorage.setItem("user", JSON.stringify(this.loginService.user));
		localStorage.setItem("modules", JSON.stringify(userModules["modules"]));
		localStorage.setItem("customer", JSON.stringify(userModules["customer"]));
		const url = this.authService.createAccessTokenURL(userModules["scopes"], this.loginService.user.googleID);
		window.location.href = url;
	}

	private legalPrompt(userModules, idToken, type = "tos") {
		const config = {
			width: "600px",
			data: {
				confirm: false,
				idToken: idToken,
			},
		};

		const dialogRef =
			type == "tos" ? this.dialog.open(TosPromptComponent, config) : this.dialog.open(EuaPromptComponent, config);

		dialogRef.afterClosed().subscribe((result) => {
			if (!result) {
				const preText =
					type == "tos"
						? "Terms of Service must be approved by an organization administrator before users are allowed to access Admin+. "
						: "The End-User Agreement must be checked before signing into Admin+ for the first time. ";
				this.undoLoginAction(
					preText + "Please contact Trafera by sending an email to google.support@trafera.com if you have any questions."
				);
			} else {
				this.prepareAccessTokenRequest(userModules, idToken);
			}
		});
	}

	private userPrompt(authObject, freeTrial) {
		const dialogRef = this.dialog.open(UserPromptComponent, {
			width: "600px",
			disableClose: true,
		});

		dialogRef.afterClosed().subscribe(async (result) => {
			if (result) {
				freeTrial.numUsers = !isNaN(result) ? result : 1;
				const response = await this.freeTrialService.createFreeTrial(freeTrial);
				if (response) {
					this.login(authObject);
				}
			} else {
				this.undoLoginAction("Failed to get user count");
			}
		});
	}

	private undoLoginAction(message: string, dialog = false) {
		//Remove Loader
		this.navService.loading.next(false);

		//Remove Left Nav Stuff
		this.leftNavService.sidenav?.toggle();

		//Remove User
		localStorage.removeItem("user");
		this.loginService.user = null;

		if (!dialog) {
			if (message) this.notificationService.notify(message);

			//Route Home
			this.router.navigateByUrl("");
		} else {
			this.dialog.open(RenewTokenDialogComponent, {
				width: "500px",
				disableClose: true,
				data: {
					message: message,
				},
			});
		}
	}

	updatePermissions(authObject, thirdParty = "google") {
		if (authObject["scope"] && this.loginService.user) {
			const authScopes = authObject["scope"].split(" ");
			if (authScopes.length) {
				if (!this.loginService.user.permissions) {
					this.loginService.user.permissions = [];
				}

				const found = this.loginService.user.permissions.filter((n) => n.name == thirdParty);

				if (!found.length) {
					const permission: Permission = {
						name: thirdParty,
						expires: 0,
						accessToken: "",
						idToken: "",
						scopes: [],
					};
					this.loginService.user.permissions.push(permission);
				}

				for (const p of this.loginService.user.permissions) {
					if (p.name == thirdParty) {
						p.expires = new Date().getTime() + parseInt(authObject["expires_in"]) * 1000;
						p.accessToken = authObject["access_token"];
						for (const s of authScopes) {
							const scope: Scope = {
								name: s,
								accepted: true,
								required: false,
							};
							p.scopes.push(scope);
						}
					}
				}
			}

			this.updateUser(this.loginService.user);
		}
	}
}
